@media screen and (max-width:1399px) {
  .post_info {
    max-width: 240px;
  }
}

@media screen and (max-width:1299px) {
  .container {
    max-width: 100%;
  }
}

@media screen and (max-width:1199px) {
  .headernav .container {
    padding: 0 5rem 0 1rem;
  }

  .headernav.plansheader .container {
    padding: 0 0.8rem;
  }

  .oninq {
    padding: 30px 30px 30px 420px;
  }

  .hpform .form-check.typecheckbox {
    width: 50%;
  }

  .card-top.border-end {
    border-right: none !important;
  }

  .filter_plans {
    width: 0 !important;
    left: -300px;
    position: fixed;
    height: 100%;
    overflow-y: scroll;
    transition: all 0.4s ease;
    top: 0;
    background-color: #fff;
    z-index: 999;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .filter_plans.show {
    width: 100% !important;
    left: 0;
    display: block;
    padding: 3.5rem 1rem 1rem;
  }

  .filter_plans .fltype {
    margin-right: 0;
    width: 100%;
    margin-bottom: 1rem;
  }

  .btn-filterdata.open {
    font-size: 0;
    position: fixed;
    top: 8px;
    right: 10px;
    border: none;
    padding: 8px;
    z-index: 9999;
  }

  .btn-filterdata.open .bx {
    display: none;
  }

  .btn-filterdata.open::before {
    content: "\ea63";
    font-family: LineIcons;
    font-size: 20px;
  }

  .qs_subscriber {
    width: 100%;
  }

  .qs_subscriber .edit-detail {
    width: calc(100% - 300px);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .travel-wizard {
    width: 100%;
  }

  .appleft {
    width: 0;
    left: -300px;
    transition: all 0.4s ease;
  }

  .appleft.show {
    width: 100%;
    left: 0;
    padding: 0 1rem 1rem;
    z-index: 10;
    height: 100%;
    overflow-y: auto;
  }

  .btn-editplan.open {
    font-size: 0;
    position: fixed;
    top: 8px;
    right: 10px;
    border: none;
    padding: 8px;
    z-index: 9999;
  }

  .btn-editplan.open .bx {
    display: none;
  }

  .btn-editplan.open::before {
    content: "\ea63";
    font-family: LineIcons;
    font-size: 20px;
  }

  .appleft .logo .mt-1 {
    margin-top: 0 !important;
  }

  .appmiddle {
    width: calc(100% - 250px);
    margin: 0 auto 0 0;
    border-left: none;
  }

  .appmiddle .fnc-quoterow .pl-w {
    width: 50%;
    margin-bottom: 1rem;
  }

  .appmiddle .fnc-quoterow .rp-w {
    width: 50%;
    margin-bottom: 1rem;
  }

  /* ===== Blog Css Start ===== */
  .post_info {
    max-width: 200px;
  }

  /* ===== Blog Css End ===== */

}

@media screen and (max-width:991px) {
  .twi-bx {
    padding: 1.5rem;
  }

  .twi-bx .icon {
    position: static;
  }

  .twi-bx .icon {
    margin-bottom: 1rem;
  }

  .oninq img {
    display: none;
  }

  .oninq::before {
    display: none;
  }

  .oninq {
    padding: 2rem;
  }

  .allpocicy li {
    margin-bottom: 1rem;
  }

  .fncard .fnc-btn {
    width: 100%;
    margin-top: 1rem;
  }

  .fncard .fnc-quote {
    width: 100%;
    padding-right: 0;
  }

  .fnc-quoterow {
    width: 100%;
    margin-top: 1rem;
  }

  .fnc-quoterow .pl-w {
    width: 50%;
    margin-bottom: 1rem;
  }

  .fncard.cardrow .btn-grp {
    margin-left: auto;
  }

  .quotecard .quote-logo {
    width: 80px;
  }

  .quotecard .btn-element {
    width: 100px;
  }

  .quotecard .idvplan {
    width: calc(100% - 180px);
  }

  .quotecard .idvplan {
    width: 100%;
    padding: 0;
    margin: 1rem 0;
  }

  .quotecard .quote-logo {
    width: 130px;
    margin: 0 auto;
  }

  .quotecard .btn-element {
    width: 100%;
  }

  .fnc-quoterow .rp-w {
    width: 50%;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width:767px) {

  /* css posp datatable*/
  .order-info li .head {
    width: 100%;
    text-transform: uppercase;
  }

  .order-info li .value {
    width: 100%;
    padding-left: 0px;
    color: #01458e;
    font-weight: 500;
    word-wrap: break-word;
  }

  .order-info li .value::before {
    display: none;
  }

  .box_outer.border {
    border-style: none !important;
    padding: 0;
  }

  .box_outer.border.rounded.p-3 {
    padding: 0px !important;
  }

  .login_account .btn.btn-success {
    font-size: 0px;
    background: none !important;
    border: none;
    padding: 0px !important;
  }

  .login_account .btn.btn-success i {
    font-size: 25px;
    color: #000;
    line-height: 0;
  }

  .headernav {
    padding: 4px 0;
    position: fixed;
    top: 0;
    z-index: 999;
    border-bottom: solid 1px #F2F6FF;
    left: 0;
  }

  .headernav .logo img {
    max-width: 210px;
  }

  .mobile-nav-toggle {
    top: 5px;
  }

  .cat-list {
    justify-content: start;
  }

  .cat-list li {
    width: 50%;
    padding: 0 0.3rem;
  }

  .cat-list li .cat-bx {
    width: 100%;
  }

  .categroy::before {
    display: none;
  }


  .brands .container {
    padding: 0 2px;
  }

  .mob-border-0 {
    border: none !important;
  }

  .headernav .acc-btn {
    position: fixed;
    left: 0;
    width: 100%;
    top: 38px;
    background-color: #0A1835;
  }

  /* .headernav .acc-btn .btn {
    width: 50%;
    border-radius: 0;
  } */

  .headernav .acc-btn app-anchor {
    width: 50%;
  }

  .headernav .acc-btn .btn {
    width: 100%;
    border-radius: 0;
  }

  .headernav .acc-btn .btn.btn-signin {
    background-color: #01A54F !important;
    color: #fff;
  }

  .sec.categroy {
    margin-top: 5rem;
  }

  .headernav .header-row .Btnblock .btn {
    padding: 0.25rem 0.5rem !important;
  }

  .qs_bx .edit-detail {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .inCard .userview .dotext {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100px;
  }

  .insure_meber ul li:last-child {
    margin-right: 0;
  }

  .sidebarfilter {
    width: 0;
    left: -300px;
    position: fixed;
    height: 100%;
    overflow-y: scroll;
    transition: all 0.4s ease;
    top: 0;
    background-color: #fff;
    z-index: 999;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sidebarfilter.show {
    width: 100%;
    left: 0;
  }

  .btn-filter.open {
    font-size: 0;
    position: fixed;
    top: 8px;
    right: 10px;
    border: none;
    padding: 8px;
    z-index: 9999;
  }

  .btn-filter.open .bx {
    display: none;
  }

  .btn-filter.open::before {
    content: "\ea63";
    font-family: LineIcons;
    font-size: 20px;
  }

  .hlplanModal.show {
    width: 100%;
    left: 0;
    margin: 0 auto;
  }

  .hlplanModal .modal-footer {
    width: 100%;
  }

  .fncard {
    display: block;
  }

  .fncard .fnc-info {
    width: 100%;
    padding-left: 0;
    margin-top: 1rem;
  }

  .fullviewmodal .modal-content .container {
    max-width: 100%;
    padding: 0;
  }

  .tabs .nav-tabs .nav-link {
    padding: 0.5rem;
  }

  ul.half li {
    width: 100%;
  }

  .btn.w-50 {
    width: 100% !important;
  }

  .addonrow {
    display: block;
    text-align: center;
  }

  .addonrow .form-group {
    width: 100%;
    margin-top: 1rem;
    text-align: left;
    padding-left: 0;
  }

  .qs_subscriber {
    display: block;
  }

  .qs_subscriber .edit-detail {
    width: 100%;
    margin-top: 1rem;
  }

  .hlplanModal.homeinsModal.show {
    width: 90%;
  }

  .appright {
    position: static;
    width: 100%;
    height: auto;
  }

  .appmiddle {
    width: 100%;
    border-right: none;
  }

  /***services-pages****/

  .insurance_form {
    padding: 0px 0px;
  }

  .box_design {
    padding: 12px;
  }

  .headernav .logo img {
    max-width: 160px;
  }

  .mobile-nav .menu-nav {
    position: relative;
    top: 0px;
  }

  .headernav .top-header {
    display: none;
  }

  .mtop {
    margin-top: 4rem;
  }

  .mrtop-5 {
    margin-top: 5rem;
  }

  .private-header .header-row {
    padding: 3px 0px 3px 0;
  }

  .public-header .header-row {
    padding: 3px 40px 3px 0;
  }

  .siteInfo .sitehelp {
    margin-left: 0;
    width: 100%;
    justify-content: end;
    display: none;
  }

  .siteInfo .sitehelp li {
    padding: 0 5px;
  }

  .sidebar-header .logo {
    width: 150px;
  }

  .header-row .logo img {
    max-width: 142px;
  }

  .public-header .top-header .siteInfo {
    position: absolute;
    top: 43px;
    left: 0;
    width: 100%;
  }

  .public-header .top-header {
    border: none;
  }

  .siteInfo .btnl {
    width: 100%;
  }

  .btnl .loginBtn {
    width: 50%;
  }

  .btnl .loginBtn .btn {
    width: 100%;
  }

  .public-header .container {
    padding: 0 5px;
  }

  .mobile-nav-toggle {
    position: fixed;
    background: #fff;
    color: #201234;
    border: solid 1px #fff;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 8px;
    right: 8px;
    font-size: 21px;
    border-radius: 3px;
    z-index: 9999999999;
    box-shadow: 0 6px 16px rgba(52, 105, 203, .16);
  }

  .mobile-nav-toggle.active .lni-menu::before {
    content: "\ea63";
  }

  .public-header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 99999;
  }

  .achive-bx .iconsBg {
    margin: inherit;
  }

  .achive-bx .content {
    width: auto;
  }

  .partner-list li {
    width: 50%;
  }

  .partner-list .partner-bx,
  .brands .partner-list .partner-bx {
    width: 100%;
  }

  .menu .main-nav {
    position: fixed;
    left: -300px;
    top: 0;
    width: 0;
    height: 100%;
    overflow-y: auto;
    transition: all 0.5s ease;
    background-color: #fff;
    padding: 0rem 1rem 1rem;
    z-index: 999;
  }

  .menu .main-nav.menu-open {
    left: 0;
    width: 100%;
  }

  .menu .main-nav .menu-nav li {
    width: 100%;
  }

  .main-nav li.drop-down .submenu {
    position: relative;
    top: 0;
    border: none;
    box-shadow: none;
    padding-left: 1rem;
  }

  .main-nav li.drop-down .submenu li {
    border-bottom: none;
  }

  .header-row .main-nav .logo img {
    max-width: inherit;
  }

  .header-row .main-nav .logo {
    border-bottom: solid 1px #f5f5f8;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
  }

  .header-top-2 .top-header .siteInfo li {
    padding: 0 5px;
  }

  .serviceForm.basicInfo {
    margin-top: 5rem;
    padding-bottom: 3rem !important;
  }
  .pos_home .serviceForm.basicInfo{
    margin-top: 0;
  }

  .header_area.custom-sidebar {
    left: -300px;
    top: 0;
    width: 0;
    transition: all 0.5s ease;
    z-index: 99999999;
  }

  .header_area.custom-sidebar.sidenav-open {
    left: 0;
    width: 100%;
  }

  .pos_home {
    padding-left: 0;
  }

  .posp-dashboard .categorie_name_list .services-list li {
    width: 50%;
    padding: 0 0.3rem;
  }

  .header-user-info {
    width: 150px;
  }

  .header-user-info .id_tril_emp {
    display: block;
    overflow: hidden;
    width: 100px;
  }

  .header-user-info .id_tril_emp span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 0 0 4px;
  }

  .header-user-info .dropdown-toggle::after {
    bottom: 4px;
  }

  .header-user-info .dropdown-toggle.arrow_drop_up::after {
    bottom: 9px;
  }

  .dropdown.user_lispro {
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .pos_home .mobile-nav-toggle {
    top: 38px;
  }

  .pos_home .mobile-nav-toggle.active {
    top: 1px;
  }

  .leftSideBar .mobile-nav-toggle {
    top: 38px;
  }

  .leftSideBar .mobile-nav-toggle.active {
    top: 1px;
  }

  .sidebar-header .header-row {
    padding: 0px 50px 0 8px;
  }

  .sidebar-content-body .main_container {
    padding: 15px;
  }

  .pf-tabs .mat-mdc-tab-header {
    width: 110px;
  }

  .pf-tabs {
    padding: 0 !important;
  }

  .mat-mdc-tab-list {
    width: 100%;
  }

  .pf-tabs .mat-mdc-tab-labels .mdc-tab {
    padding-left: 0;
  }

  .pf-tabs .mdc-tab__text-label .icon {
    display: none;
  }

  .mdc-tab__text-label .text-label {
    width: 100%;
  }

  .pf-tabs .mdc-tab__text-label {
    padding: 0 8px;
    white-space: normal;
    line-height: 19px;
  }

  .pf-tabs .mat-mdc-tab-body-wrapper {
    width: calc(100% - 110px);
    padding: 1rem;
  }

  .top-header .container {
    padding: 0 3px;
  }

  .main-nav li.drop-down .submenu.open {
    height: auto;
    display: block;
  }

  .cdk-overlay-container{
    z-index: 999999999;
  }
  .dbmatmenu.mat-mdc-menu-panel{width:100%;}
  .sidebar-footer .contact_details li{width: 100%; padding: 0;}
  .table-nowrap .table_header th, .table-nowrap td{white-space: nowrap;}
  .order-filters{width: 50%; padding-left: 10px;}
  .order-filters .search-filter{width: 50%; padding: 0 2px;}
  .order-filters .search-filter.date_bx.ms-3{margin-left: 0!important;}
  h1.fs-5{font-size: 15px!important;}

  /* ===== Blog Css Start ===== */
  .blog .blogger_detail {
    display: block;
  }

  .blog .blogger_detail li {
    width: 100%;
    margin-bottom: 8px
  }

  .blog .blogger_detail .date_calendar {
    width: 30px
  }

  /* ===== Blog Css End ===== */

}

@media screen and (max-width:374px) {

  .header-row .logo img {
    max-width: 110px;
  }

  .acc-btn .btn {
    font-size: 13px;
    font-weight: normal;
    padding: 8px 5px;
  }

  .inCard .userview .dotext {
    width: 75px;
  }

  .top-header .siteInfo li a i {
    display: none;
  }

  .pos_home .mobile-nav-toggle {
    top: 32px;
  }

  .exam-buttons .btn {
    width: 100% !important;
    margin: 0px 0 10px 0 !important;

  }

  /* ===== Blog Css Start ===== */
  .post_info {
    max-width: 150px;
  }

  /* ===== Blog Css End ===== */
}