.danger-snackbar {
    --mat-mdc-snack-bar-button-color: #ffffff;
    --mdc-snackbar-container-color: #ff0000;
    --mdc-snackbar-supporting-text-color: #ffffff;
}

.custom-error {
    color: red;
}

.mini-icon {
    vertical-align: bottom;
}

.faq .faqs-design {
    box-shadow: none !important;
    background-color: #fff;
    border-bottom: solid 1px #d5d6d8;
    padding: 0;
    border-radius: 0px !important;
}
.mat-expansion-panel-header{height: auto !important; transition: none !important; padding: 16px 24px !important;}
.mat-expansion-panel-spacing {
    margin: 0px 0px !important;
}

.mat-expansion-panel-header.mat-expanded {
    height: auto !important;
}

.faq .mat-expansion-panel-header-title {
    font-size: 17px;
}


/* faqs.component.css */
.mat-expansion-panel-header.mat-expanded {
    height: auto !important;
}

.mat-expansion-panel-header.mat-expanded .mat-expansion-panel-header-description {
    opacity: 1;
}

.mat-expansion-panel-body {
    padding: 0 24px 16px;
}