body {
  color: #0A1835;
  overflow-x: hidden;
}

html,
body {
  height: 100%;
}

app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

app-home {
  flex: 1;
  min-height: 100vh;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style: none;
}

a {
  text-decoration: none;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

button:focus,
.btn:focus,
.accordion-button:focus {
  outline: none;
  box-shadow: none;
}

a,
.btn,
button,
img {
  transition: all 0.5s ease;
}

/* ===== Header Css Start ===== */
.top-header .siteInfo {
  display: flex;
  width: 100%;
  justify-content: end;
  color: #fff;
}

.public-header .top-header {
  border-top: solid 3px #01A54F;
  border-bottom: solid 1px #ddd;
}

.private-header {
  padding: 0px 0 5px 0;
}

.header-top-2 .top-header {
  padding: 3px 0px;
  background-color: #0A1835;
}

.header-top-2 .top-header .siteInfo li {
  padding: 0 10px;
}

.header-top-2 .top-header .siteInfo li a {
  color: #fff;
  font-size: 14px;
}

.sidebar-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
}

.sidebar-header .header-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px 20px;
}

.sidebar-header .logo {
  width: 240px;
}

.sidebar-header .logo a {
  display: block;
  padding: 5px 0;
}

.header-user-info {
  display: flex;
  align-items: center;
}

.header-user-info .user_lispro {
  border: solid 1px #ccc;
  padding: 0px 0px 5px 5px;
  border-radius: 50px;
}

.header-user-info .full_swidl {
  width: 100%;
  display: inline-flex;
  padding: 0 33px 0px 0px;
  align-items: center;
}

.header-user-info .img_user {
  width: 25px;
  padding: 0;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 3px;
}

.header-user-info .img_user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-user-info .id_tril_emp {
  display: inline-grid;
}

.header-user-info .id_tril_emp span {
  font-size: 13px;
  width: 100%;
  float: left;
  line-height: 15px;
  text-align: left;
  padding: 0 0 0 9px;
}

.header-user-info .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: none !important;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-image: url(../images/icons/icon-arrow.png);
  height: 13px;
  width: 20px;
  background-repeat: no-repeat;
}

.header-user-info .dropdown-toggle.arrow_drop_up::after {
  rotate: 180deg;
}

.header-user-info-detail {
  width: 215px;
}

.header-user-info-detail .header-user-ul li .dropdown-item {
  font-weight: 400;
  cursor: pointer;
}

.header-user-info-detail .header-user-ul li .dropdown-item:hover {
  background-color: #f5f8f8;
}

.mat-mdc-menu-content {
  padding: 1px 0 0 0 !important;
}

.header-user-info-detail .header-user-ul .user_certificate {
  text-align: center;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}

/*
.header-user-info-detail .header-user-ul .user_certificate img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}*/
.header-user-info-detail .header-user-ul .user_id {
  text-align: center;
  width: 100%;
  display: inline-block;
  font-size: 14px;
}

.header-user-info-detail .header-user-ul .last_login {
  background-color: #e7f2ff;
  padding: 8px 0px;
  margin: 8px 0 0 0;
  text-align: center;
}

.header-user-info-detail .header-user-ul li {
  border-bottom: solid 1px #ebebeb;
  padding: 0px 0 0px 0;
}

.header-user-info-detail .header-user-ul li:last-child {
  border: none;
}

.header-user-info-detail .header-user-ul li .dropdown-item {
  padding: 6px 10px;
}

.header-user-info-detail .header-user-ul .dropdown-item i {
  font-size: 18px;
  margin: 4px;
}

.header-user-info-detail .header-user-ul .profile_bar {
  border-bottom: solid 1px #ccc;
  padding-bottom: 0px;
}

.header-user-info-detail .header-user-ul {
  border: solid 1px #d1d1d1;
  border-radius: 5px;
}

.nav-menu li.has-child>a {
  position: relative;
}

.sidebar-content-ul.sub-menu {
  background-color: #fff !important;
  padding-left: 25px;
}

.sidebar-content-ul.sub-menu li a {
  color: #0A1835;
  font-size: 13px;
  display: block;
  padding: 13px 20px;
  font-weight: normal;
}

.sidebar-content-ul.sub-menu li a:hover {
  color: #01A54F;
}

.header-user-info-detail .header-user-ul .profile_bar p {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
}

/* ===== Header Css End ===== */

.siteInfo {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
}

.siteInfo .sitehelp {
  display: flex;
  flex-wrap: wrap;
  margin-left: 1rem;
}

.siteInfo .sitehelp li {
  padding: 0 10px;
  border-right: solid 1px #ddd;
}

.siteInfo .sitehelp li:last-child {
  border-right: none;
}

.siteInfo .sitehelp li a {
  color: #0A1835;
  font-size: 14px;
  display: block;
  padding: 3px 0;
}

.btnl {
  display: flex;
  flex-wrap: wrap;
}

.btnl a {
  font-weight: 500;
  padding: 5px 15px;
  text-transform: uppercase;
  font-size: 13px;
  border: none;
  border-radius: 0;
}

.main-nav li {
  position: relative;
  /* margin-right: 1rem; */
}

.main-nav li:last-child {
  margin-right: 0;
}

.main-nav li a {
  color: #0A1835;
  padding: 1rem 0.75rem;
  font-size: 14px;
  display: block;
}

.main-nav li a:hover,
.main-nav li.drop-down:hover>a {
  color: #01A54F;
}

.main-nav li.drop-down .submenu {
  min-width: 210px;
  display: none;
  position: absolute;
  left: 0;
  height: 0;
  top: 100%;
  z-index: 99;
  border: solid 1px #eee;
  background: #ffffff;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.15);
  border-top: solid 3px #01A54F;
  transition: ease all 0.4s;
}

@media screen and (min-width:768px) {
  .main-nav li.drop-down:hover .submenu {
    height: auto;
    display: block;
  }
}

.main-nav li.drop-down>a {
  position: relative;
}

.main-nav li.drop-down>a::after {
  position: absolute;
  content: "\e9ac";
  font-family: boxicons;
  top: 9px;
  right: -11px;
  font-size: 25px;
  color: #01A54F;
}

.main-nav li.drop-down:hover>a:after {
  content: "\e9b9";
}

.main-nav li.drop-down .submenu li {
  margin-right: 0;
  border-bottom: solid 1px #f1f1f1;
}

.main-nav li.drop-down .submenu li a {
  white-space: nowrap;
  padding: 0.875rem 1rem;
  text-transform: none;
  font-weight: 400;
  font-size: 15px;
}

.btn {
  border-radius: 3px;
  font-size: 15px;
  font-weight: 600;
}

.main-nav li.drop-down .submenu li a:hover {
  color: #01A54F;
  background-color: #f5f5f8;
}

.main-nav li.drop-down .submenu li a i {
  width: 23px;
}

.btn-green {
  background-color: #01A54F;
  border-color: #01A54F;
  color: #fff;
}

.btn-green:focus-visible {
  background-color: #01A54F;
  border-color: #01A54F;
  color: #fff;
}

.btn-green:hover {
  background-color: #56c58b;
  border-color: #56c58b;
  color: #fff;
}

.btn-dark {
  background-color: #0A1835;
  border-color: #0A1835;
}

.lg-grbg {
  background: rgb(253, 255, 254);
  background: -moz-linear-gradient(top, rgba(253, 255, 254, 1) 0%, rgba(243, 250, 246, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(253, 255, 254, 1) 0%, rgba(243, 250, 246, 1) 100%);
  background: linear-gradient(to bottom, rgba(253, 255, 254, 1) 0%, rgba(243, 250, 246, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdfffe', endColorstr='#f3faf6', GradientType=0);
}

.bg-lgwhite {
  background-color: #fdfffe;
}

.text-green {
  color: #01A54F;
}

.cat-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.cat-list li {
  padding: 0 0.8rem;
  margin-bottom: 1.6rem;
}

.cat-list li .cat-bx {
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #F2F6FF;
  height: 95px;
  width: 130px;
  transition: all 0.5s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  margin: 4px auto 8px auto;
}

.cat-list li a {
  color: #212529;
}

.cat-bx::before {
  content: "";
  transition: all 0.5s ease;
  border: solid 1px transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
}

.cat-list li a:hover .cat-bx::before {
  border: solid 1px #01A54F;
}

.cat-list li a:hover {
  color: #01A54F;
}

.fw-semi {
  font-weight: 500 !important;
}

.ins-nav {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.ins-nav li {
  font-weight: 500;
  margin-bottom: 0.85rem;
  width: 100%;
  position: relative;
  padding-left: 2.2rem;
}

.ins-nav li:last-child {
  margin-bottom: 0;
}

.ins-nav li::before {
  content: "\e9a4";
  font-family: boxicons;
  position: absolute;
  top: -6px;
  left: 0;
  color: #01A54F;
  font-size: 25px;
}

.ins-nav.no-available li::before {
  content: "\ea63";
  font-family: LineIcons;
  color: #f34653;
  top: 0;
  font-size: 18px;
}

.bg-light {
  background-color: #f9feff !important;
}

.bg-lgreen {
  background-color: #d2f7e2;
}

.twi-bx {
  padding: 3.5rem 2rem 2rem;
  position: relative;
  height: 100%;
}

.twi-bx .icon {
  width: 80px;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: solid 1px #d5d5d8;
  position: absolute;
  top: -40px;
}

.bg-lgpurple {
  background-color: #fdf7fb;
}

.bg-gray {
  background-color: #f6f4fb !important;
}

.bg-upgray {
  background-color: #f4f5f7;
}

.bg-lgyellow {
  background-color: #fefbec;
}

.f-share {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.f-share li {
  margin: 0 5px;
}

.f-share li a {
  color: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.f-share li a:hover {
  color: #01A54F;
}

.f-nav {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.f-nav li {
  width: 100%;
}

.f-nav li a {
  color: #fff;
  padding: 8px 0;
  display: inline-block;
  font-size: 15px;
}

.f-nav li a i {
  width: 23px;
}

.f-nav li a:hover {
  color: #01A54F;
}

.bg-lgdark {
  background-color: #323536;
}

.iconsBg {
  display: block;
}

.catsprite {
  width: 60px;
  height: 60px;
  background-size: 370px auto;
}

.btn-border-green {
  border: solid 1px #01A54F;
  color: #01A54F;
  background-color: #fff;
}

.btn-border-green:hover {
  border: solid 1px #01A54F;
  background-color: #01A54F;
  color: #fff;
}

.categroy {
  position: relative;
}

.categroy .container {
  position: relative;
  z-index: 10;
}

.categroy::before {
  width: 100%;
  height: 100%;
  background: url(/images/header-bg.png) no-repeat 0 0;
  background-size: cover;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
}

.achive-bx .iconsBg {
  width: 60px;
  height: 60px;
  margin: 0 auto;
}

.achive-bx .content {
  width: calc(100% - 60px);
  padding-left: 1rem;
}

.award-bx .iconsBg {
  width: 100px;
  height: 100px;
  background-size: 750px auto;
  margin: 0 auto;
}

.how-work .iconsBg {
  width: 60px;
  height: 60px;
  background-size: 795px auto;
  margin: 0 auto;
}

.partner-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}

.partner-list li {
  padding: 0 0.8rem;
  margin-bottom: 1.6rem;
}

.partner-list .partner-bx {
  width: 150px;
}

.partnerBg {
  display: block;
}

.partner-bx .partnerBg {
  width: 110px;
  height: 65px;
  background-size: 950px auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brandBg {
  display: block;
}

.brands .partner-list .partner-bx {
  width: 180px;
}

.hs-bx .icon {
  width: 30px;
  font-size: 25px;
  text-align: center;
}

.hs-bx .content {
  width: calc(100% - 30px);
  padding-left: 10px;
  word-break: break-all;
}

.oninq {
  position: relative;
  background-color: #EFF3F3;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 55px 30px 55px 420px;
}

.oninq img {
  position: absolute;
  left: 20px;
  content: "";
  bottom: 0;
}

.allpocicy {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.allpocicy li {
  position: relative;
  padding-left: 35px;
  margin-right: 1rem;
}

.allpocicy li::before {
  position: absolute;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: solid 1px #01A54F;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "\e9a4";
  font-family: boxicons;
  color: #01A54F;
}

.fhr-nav.f-nav li {
  margin-right: 1.3rem;
  width: auto;
}

.fhr-nav.f-nav li a {
  padding: 0;
}

footer.footer {
  background: url(/images/footer-bg.png) no-repeat center center #212529;
  width: 100%;
  min-height: 370px;
  background-size: cover;
}

.typeradio.form-check,
.typecheckbox.form-check {
  padding: 0 1rem 0 0;
  margin-bottom: 1rem;
  margin-right: 0;
  height: 100%;
}

.typeradio.form-check.question_aws,
.typecheckbox.form-check.question_aws {
  padding: 0;
}

.typeradio .form-check-input[type=radio],
.typecheckbox .form-check-input[type=checkbox] {
  opacity: 0;
  display: none;
}

.typeradio .form-check-label {
  background-color: #fff;
  border: solid 1px #d8d8d8;
  padding: 7px;
  text-align: center;
  min-width: 100px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.typeradio .form-check-input[type=radio]:checked+label {
  background-color: #f2fcf8;
  border: solid 1px #36b37e;
  color: #36b37e;
}

.typeradio .form-check-label .icon {
  width: 28px;
  height: 30px;
  background-size: 26px 287px;
  display: block;
  margin-right: 8px;
}

.typecheckbox .form-check-label {
  background-color: #fff;
  border: solid 1px #d8d8d8;
  padding: 10px 10px 10px 34px;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.typecheckbox .form-check-input[type=checkbox]:checked+label {
  background-color: #f2fcf8;
  border: solid 1px #36b37e;
  color: #36b37e;
}

.typecheckbox .form-check-label::before {
  content: "\e9be";
  font-family: boxicons;
  line-height: 45px;
  position: absolute;
  top: 0px;
  height: 100%;
  font-size: 18px;
  left: 10px;
}

.typecheckbox .form-check-input[type=checkbox]:checked+label::before {
  content: "\e9a8";
  color: #36b37e;
}

.hpform .form-check.typecheckbox {
  width: 33.33%;
}

.form-group {
  position: relative;
  width: 100%;
}

.form-control-custom {
  height: 53px;
  padding: 11px 1rem 0 1rem;
}

select.form-control {
  cursor: pointer;
  padding: 0 1rem;
}

textarea.form-control {
  height: 80px;
  padding: 20px 1rem 10px 1rem;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.memCount.open {
  display: flex;
}

.memCount {
  display: none;
  flex-wrap: wrap;
  width: 60px;
  height: 20px;
  position: absolute;
  bottom: -8px;
  right: 0px;
  left: 0;
  margin: 0 auto;
  border: solid 1px #01A54F;
  border-radius: 30px;
  background-color: #01A54F;
  z-index: 10;
}

.memCount .bx {
  width: 18px;
  height: 100%;
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
}

.memCount .qtyValue {
  width: calc(100% - 36px);
  height: 18px;
  border: none;
  padding: 0 3px;
  border-left: solid 1px #01A54F;
  border-right: solid 1px #01A54F;
  text-align: center;
  font-size: 13px;
}

.typecheckbox {
  position: relative;
}

.hpform hr {
  opacity: 0.05;
}

.circle-bx {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.qs_bx {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-label {
  cursor: pointer;
  height: 100%;
}

.shadow-sm {
  box-shadow: 0 2px 6px rgba(0, 0, 0, .2) !important;
}

.insure_meber ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.insure_meber .inCard {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  background-color: #fafafa;
  border: solid 1px #d7d8d9;
  border-radius: 5px;
  align-items: start;
  position: relative;
}

.insure_meber ul li.selected .inCard {
  background-color: #f2fcf8;
  border: solid 1px #36b37e;
  font-weight: 600;
}

.insure_meber ul li a {
  color: #212529;
}

.insure_meber ul li.selected a {
  color: #36b37e;
}

.inCard .imgspace {
  width: 36px;
}

.inCard .userview {
  width: calc(100% - 36px);
  padding-left: 10px;
}

.insure_meber ul li {
  margin-right: 1rem;
}

.edit-detail a {
  font-weight: 600;
  border-bottom: dotted 2px #333846;
  color: #212529;
  padding-bottom: 3px;
}

.bg-lghgray {
  background-color: #f5f6f8;
}

.fncard {
  padding: 1rem;
}

.border-success {
  border-color: #01A54F;
}

.fncard {
  border: solid 1px #f2f3f4;
  padding: 1rem;
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 8px;
  align-items: start;
  box-shadow: 0px 6px 2px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.4s ease;
  margin-bottom: 2.3rem;
  overflow: hidden;
}

.fncard .fnc-img {
  width: 100px;
  border: solid 1px #f5f6f6;
  border-radius: 5px;
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.fncard .fnc-info {
  width: calc(100% - 100px);
  padding-left: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.fncard .fnc-quote {
  width: calc(100% - 240px);
  padding-right: 1rem;
}

.fncard .features {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 0.5rem;
}

.fncard .features li {
  display: block;
  padding: 2px 8px;
  border-radius: 3px;
  border: 1px solid #dfe1e6;
  font-size: 13px;
  margin-right: 6px;
  margin-bottom: 6px;
}

.fncard .features li:last-child {
  padding: 0;
  border: none;
}

.fncard .features li:last-child a {
  font-size: 13px;
  color: #212529;
  border-bottom: dotted 2px #f34653;
}

.tw-link {
  padding: 0 0 3px 0;
  font-weight: 600;
  background: none;
  border: none;
  border-bottom: dotted 2px #333846;
  line-height: normal;
}

.fncard .fnc-btn {
  width: 240px;
}

.fnc-btn .covers {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.fnc-btn .covers .cover-amount {
  width: 50%;
  padding-right: 5px;
}

.fnc-btn .covers .cover-hospital {
  width: 50%;
  padding-left: 5px;
}

.btn-red {
  background-color: #f34653;
  border: solid 1px #f34653;
  color: #fff;
}

.btn-red:hover {
  background-color: #df1f2d;
  color: #fff;
}

.comparePlan .form-check-label {
  font-size: 14px;
  font-weight: 600;
}

.text-red {
  color: #f34653;
}

.bg-lg-green {
  background-color: #f2fcf8 !important;
}

.border-green {
  border-color: #36b37e !important;
}

.text-lg-green {
  color: #36b37e;
}

.fndiscount {
  position: absolute;
  top: -12px;
  right: 20px;
}

@keyframes click-wave {
  0% {
    height: 10px;
    width: 10px;
    opacity: 0.5;
    position: relative;
  }

  100% {
    height: 40px;
    width: 40px;
    margin-left: -13px;
    margin-top: -13px;
    opacity: 0;
  }
}

.comparePlan .form-check-input::after {
  -webkit-animation: click-wave 2s infinite;
  -moz-animation: click-wave 2s infinite;
  animation: click-wave 2s infinite;
  background: #01A54F;
  content: "";
  display: block;
  position: relative;
  z-index: 100;
}

.comparePlan .form-check-input:checked::after {
  display: none;
}

.fncard:hover {
  box-shadow: 0px 6px 2px 0px rgba(0, 0, 0, 0.2);
  border: solid 1px #ccc;
}

.comparePlan-panel {
  box-shadow: 0 -6px 16px rgba(37, 56, 88, .16);
  position: fixed;
  bottom: -100px;
  transition: all 0.5s ease;
  height: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
  overflow: hidden;
  border-top: solid 1px #ccc;
}

.comparePlan-panel.open {
  bottom: 0;
  height: auto;
}

.compSelect {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  position: relative;
  padding: 1.3rem 1rem;
  border-right: solid 1px #d8d8d8;
}

.compSelect .fnclogo {
  width: 80px;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: solid 1px #e8e9e8;
}

.compSelect .fncinfo {
  width: calc(100% - 80px);
  padding-left: 1rem;
}

.compSelect .fncinfo h2,
.compSelect .fncinfo .bname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.compSelect .btn-close {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 13px;
  right: 13px;
  border: solid 1px #ccc;
  border-radius: 50%;
  background-size: 11px 11px;
}

.disclaimer ul li {
  padding: 5px 0;
}

.selectedQuote.remove {
  display: none;
}

.hlplanModal {
  position: fixed;
  right: -300px;
  left: auto;
  width: 0;
  transition: all 0.5s ease;
  top: 0;
  height: 100%;
  background-color: #fff;
  z-index: 99999;
}

.hlplanModal.show {
  right: 0;
  width: 450px;
}

.hlplanModal .modal-dialog {
  height: calc(100% - 90px);
  margin: 0;
  padding-bottom: 140px;
}

.hlplanModal .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
}

.btn-close {
  opacity: 1;
}

.sm-input.form-control {
  border: none;
  background-color: transparent;
  height: 27px;
  padding: 0;
  line-height: 27px;
  color: #000;
  font-weight: 600;
}

.btn-close {
  opacity: 1;
}

.typecheckbox #son.form-check-input[type=checkbox]:checked~.memCount {
  display: flex;
}

.typecheckbox #daughter.form-check-input[type=checkbox]:checked~.memCount {
  display: flex;
}

.select-dd {
  position: absolute;
  top: 0;
  right: 6px;
  width: 80px;
  height: 100%;
  display: flex;
  align-items: center;
  border-left: solid 1px #ababab;
}

.select-dd select.form-control {
  background-position: 60px 10px;
  padding: 0 5px !important;
  font-weight: normal !important;
}

.select-dd select.form-control option {
  color: #212529 !important;
}

.typecheckbox .form-check-input[type=checkbox]:checked~.select-dd {
  border-left: solid 1px #36b37e;
}

.typecheckbox .form-check-input[type=checkbox]:checked~.select-dd select {
  color: #36b37e;
}

.hlplanModal .modal-body {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem 1rem 1rem 0;
}

.hlplanModal .modal-footer {
  position: fixed;
  right: 0;
  width: 450px;
  bottom: 0;
}

.fullviewmodal {
  background-color: #f6f4fb;
}

.fullviewmodal .modal-dialog {
  max-width: 100%;
  margin: 0;
  width: 100%;
  height: 100%;
}

.fullviewmodal .modal-content {
  border: none;
  background-color: #f6f4fb;
  border-radius: 0;
  padding-bottom: 2rem;
}

.fullviewmodal .modal-content .container {
  max-width: 1170px;
}

.card-top {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.card-top .fnc-img {
  width: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.card-top .fncName {
  width: calc(100% - 100px);
  padding-left: 1rem;
}

.nav-tabs .nav-link {
  border-radius: 0;
  border: none;
  border-bottom: solid 2px transparent;
  color: #212529;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: solid 2px #f34653;
  font-weight: 500;
  color: #f34653;
}

.cartamoutinfo ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.fullviewmodal .cartamoutinfo ul li {
  border-bottom: none;
}

.cartamoutinfo ul li:first-child {
  padding-top: 0;
}

.cartamoutinfo ul li {
  padding: 10px 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  border-bottom: dashed 1px #212121;
}

.cartamoutinfo ul li .value {
  width: 50%;
  text-align: right;
  font-weight: 500;
}

.cartamoutinfo ul li .head {
  width: 50%;
}

.summery-bx {
  position: sticky;
  top: 70px;
}

.fullviewmodal .modal-header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.dwicon {
  position: relative;
  padding: .6rem .6rem .6rem 2.65rem;
}

.dwicon i {
  position: absolute;
  left: 10px;
  top: 8px;
}

.dwicon.nopad i {
  left: 0px;
  top: 5px;
}

.dwicon.nopad {
  padding: .2rem 0 0 1.5rem;
}

.bullets li {
  list-style-type: disc;
  margin-bottom: .5rem;
}

ul.half li {
  width: 50%;
}

ul.ins-nav.half li {
  font-size: 14px;
  margin-bottom: 2px;
  padding-left: 1.6rem;
}

ul.ins-nav.half li::before {
  top: -2px;
  font-size: 18px;
}

.typeradio.customradio .form-check-label {
  padding: 10px 10px 10px 34px;
  display: block;
  text-align: left;
}

.typeradio.customradio .form-check-label::before {
  content: "\e9be";
  font-family: boxicons;
  line-height: 45px;
  position: absolute;
  top: 0px;
  height: 100%;
  font-size: 18px;
  left: 10px;
}

.typeradio.customradio .form-check-input[type=radio]:checked+label::before {
  content: "\e9a8";
  color: #36b37e;
}

.medical .form-check-label {
  justify-content: space-between;
}

.medical .form-check-label span {
  font-size: 13px;
  color: #7a869a;
  font-weight: 500;
}

.imc-field {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.imc-field li {
  width: 50%;
  margin-bottom: 1rem;
}

.imc-field li:nth-child(odd) {
  padding-right: 1rem;
}

.imc-field li .head {
  font-size: 13px;
  color: #7a869a;
}

.imc-field li .value {
  font-weight: 500;
}

.card_reviewhead {
  width: calc(100% - 80px);
}

.rtoreg .inCard .imgspace {
  width: 30px;
}

.rtoreg .inCard .userview {
  width: calc(100% - 58px);
  padding-right: 5px;
  padding-left: 5px;
}

ul.half.full li {
  width: 100%;
}

.quotecard {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  align-items: center;
  justify-content: space-between;
}

.company_quotes .company-logo {
  width: 120px;
  text-align: center;
}

.company_quotes .company-plans {
  width: calc(100% - 120px);
  padding-left: 1rem;
}

.idvplan {
  width: calc(100% - 300px);
  padding: 0 1rem;
  text-align: center;
}

.quotecard .btn-element {
  width: 150px;
}

.modal-md {
  max-width: 370px;
}

.align-super {
  vertical-align: super;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  background: #fff;
  border: none;
  color: #000;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: #01A54F;
  color: #fff;
}

.ui-datepicker td {
  padding: 0;
  border: solid 1px #ddd;
}

.typeradio .form-check-label .smoke {
  width: 20px;
  height: 30px;
  display: block;
  margin-right: 8px;
}

.typeradio .form-check-label .nosmoke {
  width: 20px;
  height: 30px;
  display: block;
  margin-right: 8px;
}

.filter_plans {
  display: flex;
  width: calc(100% - 380px);
}

.qs_subscriber {
  display: flex;
  align-items: center;
}

.filter_plans .fltype {
  min-width: 150px;
  margin-right: 1rem;
}

.bootstrap-select.type-select {
  width: 100% !important;
}

.icon-group .bootstrap-select.type-select .btn {
  border-bottom: none;
}

.bootstrap-select.type-select .btn {
  padding: 1rem;
  border: none;
  border-bottom: 1px solid #ced4da;
  min-width: 100%;
  width: 100%;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 0;
}

.mselect-group .bootstrap-select.type-select .btn {
  border: 1px solid #ced4da;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn.btn-light,
.btn.btn-light:focus {
  background-color: #FFF;
  outline: none !important;
}

.bootstrap-select.type-select .btn:focus {
  outline: none !important;
}

.mselect-group .bs-searchbox {
  display: none;
}

.fltype .bootstrap-select.type-select .btn {
  padding: 0;
  font-size: 14px;
  border: none;
  font-weight: 500;
}

.fltype .dropdown-toggle::after {
  margin-left: 0.5em;
  margin-top: 3px;
}

.bootstrap-select>.dropdown-toggle.bs-placeholder,
.bootstrap-select>.dropdown-toggle.bs-placeholder:active,
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder:hover {
  color: #010101;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #01A54F;
}

.fncard .btn-grp {
  width: 160px;
}

.fnc-quoterow {
  width: calc(100% - 280px);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.fnc-quoterow .pl-w {
  width: 25%;
  padding: 0 10px;
}

.fnc-quoterow .rp-w {
  width: 33.33%;
  padding: 0 10px;
}

ul.auto li {
  width: auto;
  margin-right: 10px;
}

.addonrow {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
}

.addonrow .form-group {
  width: calc(100% - 130px);
  padding-left: 1rem;
}

.app-content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.appleft {
  width: 250px;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  left: 0;
  top: 0;
}

.appright {
  width: 250px;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  right: 0;
  top: 0;
}

.appmiddle {
  width: calc(100% - 500px);
  border-left: solid 1px #ccc;
  border-right: solid 1px #ccc;
  margin: 0 auto;
}

.form-control.input-field {
  border: none;
  border-bottom: solid 1px #c8c8c8;
  border-radius: 0;
  padding: 0;
  height: 42px;
}

.form-group.icon-group i {
  position: absolute;
  top: 8px;
  left: 0;
  font-size: 18px;
}

.icon-group .bootstrap-select.type-select {
  position: absolute;
  right: 0;
  top: 6px;
  width: 73px !important;
}

.icon-group .bootstrap-select.type-select .btn {
  padding: 5px;
  font-size: 14px;
}

.form-group.icon-group .input-field {
  padding: 0px 5rem 0px 1.1rem;
  font-size: 18px;
  font-weight: 500;
}

.bootstrap-select>.dropdown-toggle:after {
  margin-top: 0;
}

.icon-group .bootstrap-select.type-select .btn::before {
  content: "/";
  margin-right: 2px;
}

.bootstrap-select.type-select.fs-18 .btn {
  font-size: 18px;
}

.border-dashed {
  border-top: dashed 1px;
}

.navfilter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.navfilter li {
  width: 100%;
  border-bottom: dashed 1px #d8d8d8;
}

.navfilter li a {
  display: block;
  background-color: #f5f6f8;
  padding: 0.75rem 1rem;
  color: #201234;
}

.appmiddle .fncard {
  padding: 2.3rem 1rem 1.6rem 1rem;
  margin-bottom: 1.6rem;
}

.text-red {
  color: #f34653 !important;
}

.btn-editmodal {
  cursor: pointer;
}

.btn-editmodal::after {
  font-family: boxicons;
  content: "\ec8f";
  top: 15px;
  right: 0;
  font-size: 20px;
  color: #0d6efd;
  position: absolute;
}

.chapter_training ul.li-bullets li {
  list-style: disc;
  list-style-position: outside;
  padding: 0 0 5px 0;
  margin-left: 20px;
}

.acc-btn a {
  font-weight: normal;
  font-size: 14px;
}

.end-auto {
  right: auto !important;
}

.login-modal-backdrop.show {
  opacity: 0.8;
}

.icon-group .icon {
  position: absolute;
  top: 13px;
  left: 15px;
}

.icon-group-sign .icon {
  top: 13px;
}

.imc-field.w-100 li {
  width: 100%;
  padding-right: 0;
}

.worldmap {
  position: relative;
}

.worldmap::before {
  background: url(../images/world.png) no-repeat center bottom;
  width: 100%;
  height: 300px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background-size: contain;
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators .step-tabs {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  font-size: 14px;
  color: #97a0af;
  text-align: center;
  position: relative;
  font-weight: 600;
}

.indicators .step-tabs::before {
  border-radius: 50%;
  display: block;
  font-size: 10px;
  margin: 0 auto 2px;
  position: relative;
  z-index: -1;
  order: 2;
  content: "";
  width: 8px;
  height: 8px;
  border: 1px solid #97a0af;
  background: #97a0af;
  outline: 5px solid #f2f7ff;
}

.indicators .step-tabs::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #b3bac5;
  left: 50%;
  top: 34px;
  z-index: -3;
}

.indicators .step-tabs:last-child::after {
  display: none;
}

.indicators .step-tabs span {
  margin-bottom: 10px;
}

.serviceForm .indicators .step-tabs {
  font-size: 1rem;
}

.serviceForm .indicators .step-tabs::before,
.serviceForm .indicators .step-tabs::after {
  z-index: 10;
}

.indicators .step-tabs.step-current,
.indicators .step-tabs.step-completed {
  color: #01A54F;
}

.indicators .step-tabs.step-current::before {
  border: 1px solid #01A54F;
  background: #01A54F;
}

.indicators .step-tabs.step-completed::after {
  background: #01A54F;
}

.indicators .step-tabs.step-completed::before {
  border: 1px solid #01A54F;
  background: #01A54F;
}
.pos_home .indicators .step-tabs::after{top: 37px;}

.travel-wizard {
  width: 80%;
  margin: 0 auto;
}

.steps {
  width: 0;
  height: 100%;
  transition: all 0.4s ease-in-out;
  display: none;
}

.steps.active {
  width: 100%;
  display: block;
  transition: all 0.4s ease-in-out;
}

.form-input.invalid {
  border: 1px solid #ec2626;
  transition: all 0.4s ease-in-out;
}

.hide {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.4s ease-in-out;
}

.unhide {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition: all 0.4s ease-in-out;
}

.cmnform {
  position: relative;
}

.adtrvel .value {
  width: 85px;
}

.adtrvel .head {
  width: calc(100% - 100px);
  padding-right: 1rem;
}

.value .numCount {
  width: 100%;
  display: flex;
}

.value .numCount .bx {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #f4f5f7;
  cursor: pointer;
}

.value .numCount .qtyValue {
  width: calc(100% - 60px);
  margin: 0 auto;
  height: auto;
  padding: 0;
  border: none;
  background-color: #fff;
  text-align: center;
  font-weight: 600;
}

.form-control.form-place {
  padding: 0 1rem;
}

.typeradio.customradio.chkright .form-check-label::before {
  left: auto;
  right: 10px;
}

.typeradio.customradio.chkright .form-check-label {
  padding: 10px 30px 10px 10px;
}

.imgcol {
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
  width: 100%;
}

.hlplanModal.homeinsModal.show {
  width: 630px;
}

.hlplanModal.show .modal-dialog {
  width: 100%;
  max-width: 100%;
}

.hlplanModal.homeinsModal .modal-footer {
  width: 630px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: #cac9c9 #eee;
}

body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-track {
  background-color: #eee;
}

body::-webkit-scrollbar-thumb {
  background-color: #cac9c9;
}

body::-webkit-scrollbar-track,
body::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.signform .icon-group .form-control {
  padding: 8px 5px 0 78px;
}

.user-sign-form .signform .icon-group .form-control {
  padding: 8px 5px 0 78px;
}

.select-label.input-label {
  top: 2px;
  font-size: 13px;
  color: #000;
}

.datelabel.input-label {
  top: 2px;
  font-size: 13px;
  font-weight: 500;
}

.step_form .font_doc {
  font-size: 13px;
}

.step_form .form-control.docupld {
  padding-left: 6px !important;
  padding-top: 6px;
  padding-bottom: 6px;
  height: auto;
}



.box_design {
  border: solid 1px #fffefe;
  padding: 25px;
  box-shadow: 0px 2px 10px 0px #e3e1e1;
  border-radius: 9px;
}

.insurance_form {
  padding: 0px 50px;
}

ul.terms_of_use li {
  list-style-type: decimal;
  padding: 8px;
}


/* ===== POSP-CSS START  ===== */

.bg_box_steps {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 30px;
  background-color: #fbfffd;
  text-align: center;
  border-radius: 4px;
  border: solid 1px #e9e9e9;
  height: 100%;
}

.bg_box_steps h2 {
  font-size: 20px;
  font-weight: 600;
  padding-top: 10px;
}

.wha-posp {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px;
  background-color: #f9feff;
}

.wha-posp p {
  font-size: 16px;
  line-height: 30px;
}

.img_icons-os {
  float: left;
  width: 65px;
}

.poin_one {
  width: 100%;
  float: left;
  background-color: #fff;
  border: solid 1px #e5e5e5;
  padding: 7px 0 7px 10px;
  border-radius: 4px;
  margin: 0 0 18px 0;
}

.details_info {
  float: left;
  width: calc(100% - 80px);
}

.insure_bg {
  background-color: #f9feff;
  padding: 40px 0 40px 0;
}

.dropdown.user_lispro {
  border: solid 1px #ccc;
  padding: 0px 0px 5px 5px;
  border-radius: 50px;
}

.sidebar-content-body {
  padding-top: 104px;
}

.custom-sidebar .nav-menu li {
  border-bottom: solid 1px #f3f3f3;
}

.custom-sidebar .nav-menu li:last-child {
  border-bottom: none;
}

.custom-sidebar ul.nav-menu li img {
  margin: 0 10px 0px 0px;
}

.custom-sidebar .nav-menu li a {
  color: #0A1835;
  font-size: 14px;
  display: block;
  padding: 16px 20px;
  border-left: solid 3px transparent;
}

.custom-sidebar .nav-menu li a:hover,
.custom-sidebar .nav-menu li a.active {
  background-color: #f5f8f8;
  border-left: solid 3px #01A54F;
}

.main_container {
  padding: 20px;
}

.pos_home {
  padding-left: 270px;
  background-color: #fcfcfc;
}

.posp-dashboard .categorie_name_list .services-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: static;
  padding: 0;
  margin: 0;
  border: none;
  height: 100%;
}

.posp-dashboard .categorie_name_list .services-list li {
  padding: 0 0.8rem;
  margin: 0 0 1.6rem;
}

.posp-dashboard .categorie_name_list .services-list li a {
  color: #212529;
}

.posp-dashboard .categorie_name_list .services-list li .cat-bx {
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #f1f1f1;
  height: 95px;
  width: 130px;
  transition: all 0.5s ease;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.5rem;
}

.posp-dashboard .categorie_name_list .services-list li .cat-bx img {
  max-width: 43px;
  max-height: 43px;
}

.posp-dashboard .categorie_name_list .services-list li .cat-bx::before {
  content: "";
  transition: all 0.5s ease;
  border: solid 1px transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 8px;
}

.info-box {
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
  border-radius: .25rem;
  background-color: #fff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  min-height: 80px;
  padding: .5rem;
  position: relative;
  width: 100%;
  min-height: 60px;
}

.info-box .info-box-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1.8;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  line-height: 1.3;
  padding: 8px 10px;
}

.info-box .info-box-text {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 11px;
  text-transform: uppercase;
  justify-content: space-between;
}

.info-box .info-box-number {
  display: flex;
  margin-top: .25rem;
  font-weight: 600;
  font-size: 1.1rem;
  justify-content: space-between;
}

.info_bx_icon img {
  filter: brightness(70%);
}

.header_area {
  position: fixed;
  left: 0;
  top: 104px;
  width: 270px;
  height: 100%;
  background: #fff;
  border-right: solid 1px #f3f3f3;

}

/* ===== POSP-CSS END  ===== */

input.box_top {
  width: 50px;
  height: 46px;
  text-align: center;
  font-size: 20px;
  outline: none;
  border: solid 1px #ddd;
  border-radius: 5px;
  letter-spacing: 8px;
  padding: .375rem .75rem;
  margin-right: 4px;
}

.input-label {
  position: absolute;
  top: 2px;
  left: 16px;
  transition: all 0.2s ease-in-out;
  z-index: 10;
  font-size: 13px;
  font-weight: 500;
}

.signform .icon-group .input-label {
  left: 78px;
}

.mat-mdc-form-field-error.custom-error {
  position: absolute;
  font-size: 11px;
  display: block;
  width: 100%;
}

.step_form .form-control {
  padding-left: 50px !important;
}

.step_form .input-label {
  left: 50px !important;
}

/* Upload-file */

.drop-zone {
  width: 100%;
  height: 120px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  font-size: 16px;
  cursor: pointer;
  color: #8d8989;
  border: 2px dashed #01a54f;
  border-radius: 4px;
}

.drop-zone label.error {
  position: absolute;
  bottom: -40px;
  left: 0;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.drop-zone__thumb {
  width: 120px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  padding: 10px;
  z-index: -1;
}

.drop-zone__thumb::after {
  display: none;

}

.btn-remove {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.form-group .mat-mdc-form-field {
  width: 100%;
}

.form-group .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding: 0;
  background-color: #fff;
}

.form-group .mat-mdc-form-field-infix {
  width: 100%;
}

.form-group .mat-mdc-text-field-wrapper {
  height: 53px;
  border: solid 1px #ced4da;
}

.form-group .mat-mdc-form-field-hint-wrapper,
.form-group .mat-mdc-form-field-error-wrapper {
  padding: 0;
}

button.add_ref {
  background-color: #01a54f;
  color: #fff;
  padding: 0;
  display: inline-block;
  border-radius: 3px;
  font-size: 20px;
  border: none;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  line-height: 30px;
  font-weight: 500;
}

/*about-us css*/
.about_bg_img {
  background: url(../images/pages/about-uss.jpg) no-repeat center center #212529;
  width: 100%;
  min-height: 350px;
  background-size: cover;
}

.about_us_page .bg_one {
  background-color: #ebf1ff;
}

.about_us_page .bg_two {
  background-color: #d0f8df6e;
}

.about_us_page .bg_three {
  background-color: #f4e4fdd3;
}

.about_us_page .bg_four {
  background-color: #fcd1b56e;
}

.about_us_page .bg_five {
  background-color: #c1f5ec9f;
}

.about_us_page .bg_six {
  background-color: #e1f3916e;
}

/*contact-us page css*/
.contact_us_bg {
  background-color: #f3fff9;
}

.contact_us_section {
  background-color: #fff;
  margin: 0;
  padding: 20px 30px;
  width: 100%;
  text-align: center;
  box-shadow: 2px 1px 9px 5px #f7f7f7;
  border-radius: 7px;
  height: 100%;
}

.contact_us_section .box_heading_content {
  font-weight: 500;
}

.btn-white {
  background-color: #fff !important;
}

.text-success {
  color: #01A54F !important;
}

.bg-success {
  background-color: #01A54F !important;
}

.btn-success {
  background-color: #01A54F !important;
  border-color: #01A54F;
}

.bg-light-success {
  background-color: #f0faf5;
}

.progress-height {
  height: 8px !important;
}

.bg-lightgreen {
  background-color: #fbfffd;
}

.chapter_training .image img {
  width: 100%;
  max-width: 420px;
  margin: 40px 0px;
}

.login_account .dropdown-toggle {
  border: solid 1px #01A54F;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 4px 8px;
  background-color: #01A54F;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login_account .dropdown-toggle i {
  font-size: 18px;
}

.profileMenu {
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 3px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
}

.profileMenu a {
  padding: 10px;
  display: block;
  border-bottom: solid 1px #f3f4f5;
  width: 100%;
  font-weight: normal;
  color: #010101;
  cursor: pointer;
}

.profileMenu a:last-child {
  border-bottom: none;
}

.profileMenu a i {
  width: 18px;
}

.profileMenu a:hover {
  background-color: #f3f4f5;
}

.mat-mdc-menu-panel {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.sidebar-content-body .main_container {
  padding: 25px 20px;
}

.fs-10 {
  font-size: 10px;
}

.nav-menu li.has-child>a::after {
  position: absolute;
  content: "\e9b2";
  font-family: boxicons;
  right: 10px;
  top: 15px;
  font-size: 16px;
}

.nav-menu li.has-child>a.arrow_drop_up::after {
  content: "\e9ac";
}

.dbmatmenu.mat-mdc-menu-panel {
  transform-origin: inherit !important;
  background: #fff !important;
  border: none;
  box-shadow: none;
  border-radius: 0;
  position: absolute;
  width: 270px;
  left: 0;
}

.dbmenu {
  overflow-y: auto;
  max-height: calc(100% - 120px);
}

.custom-loader-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-mdc-progress-spinner {
  width: 50px !important;
  height: 50px !important;
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: #01A54F !important;
}

.form-control {
  height: 53px;
  padding: 10px 1rem 0 1rem;
}

.services-form .icon-group .form-control {
  padding: 0px 1rem 0 2.8rem;
}

.services-form .form-control.docupld {
  padding-left: 6px !important;
  padding-top: 6px;
  padding-bottom: 6px;
  height: auto;
}

.font_doc {
  font-size: 13px;
}

.doc_img-privews {
  border-radius: 5px;
  padding: 5px;
  overflow: hidden;
  border: solid 1px #f0f0f0;
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
}

.services-form .custom-label {
  display: block;
}

.mat-datepicker-input {
  cursor: pointer;
}

.mat-mdc-tab-header-pagination-controls-enabled .mat-mdc-tab-header-pagination {
  display: none !important;
}

.pf-tabs .mat-mdc-tab-group {
  flex-direction: inherit !important;
}

.pf-tabs .mat-mdc-tab-labels {
  width: 100%;
  flex-wrap: wrap;
}

.pf-tabs .mat-mdc-tab-labels .mdc-tab {
  width: 100%;
  align-items: center;
  justify-content: start;
  min-width: 100%;
  padding: 0 0 0 8px;
  border-left: solid 2px transparent;
  transition: all 0.4s ease;
}

.pf-tabs .mat-mdc-tab-labels .mdc-tab:hover {
  border-left: solid 2px #01a54f;
  background-color: #e5fff1;
}

.pf-tabs .mat-mdc-tab-labels .mdc-tab.mdc-tab--active {
  border-left: solid 2px #01a54f;
  background-color: #e5fff1;
}

.pf-tabs .mat-mdc-tab-header {
  width: 33.33%;
  border-right: solid 1px #ccc;
}

.pf-tabs .mat-mdc-tab-body-wrapper {
  width: 66.66%;
  padding: 0 1rem;
}

.pf-tabs .mdc-tab-indicator {
  display: none;
}

.pf-tabs .mat-mdc-tab .mdc-tab__content {
  width: 100%;
  justify-content: start;
}

.pf-tabs .mdc-tab__text-label {
  display: flex !important;
  width: 100%;
  height: 100%;
  justify-content: start;
  text-align: left;
}

.pf-tabs .mdc-tab__text-label .icon {
  width: 30px;
  text-align: center;
  margin-right: 5px;
}

.pf-tabs .mat-mdc-tab-label-container {
  border: none;
}

.pf-tabs .profile_pic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 2px #ccc;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pf-tabs .profile_pic img {
  width: 60px;

}

.pf-tabs .signform .icon-group .form-control {
  font-size: 14px;
}

.pf-tabs .mat-mdc-tab-body-content {
  overflow: hidden;
}

.pancard_img img {
  max-width: 60px;
}

.check.img_doc {
  width: 60px;
}

.cdk-overlay-pane {
  max-width: 270px;
}
.cdk-overlay-pane.mat-datepicker-popup{max-width: 100%;}

.cdk-global-scrollblock {
  top: 0 !important;
}

.custom-dialog .custom-dialog-close .mdc-button__label {
  color: #ffffff;
}
.sidebar-footer .contact_details{display: flex; width: 100%; flex-wrap: wrap; justify-content: center;}

.sidebar-footer .contact_details li {
  margin: 5px 0;
  padding: 0 5px;
}

.sidebar-footer .contact_details li i {
  background-color: #01a54f;
  border-radius: 50%;
  color: #fff;
  width: 22px;
  height: 22px;
  font-size: 14px;
  margin: 0 4px;
}

.sidebar-footer .contact_details li a {
  color: #181717;
}

.sidebar-footer .social_media li {
  display: inline;
}

.sidebar-footer .social_media li a img {
  width: 36px;
  border: solid 1px #d7d7d7;
  border-radius: 50%;
  padding: 7px;
  background-color: #fff;
}

.sidebar-footer .social_media li {
  padding: 0 10px;
}

.order-info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.order-info li:nth-child(odd) {
  background-color: #f1f1f1;
}

.order-info li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 6px 8px;
  font-size: 15px;
}

.order-info li .head {
  width: 280px;
  text-transform: uppercase;
}

.order-info li .value {
  width: calc(100% - 280px);
  padding-left: 1rem;
  position: relative;
  word-break: break-all;
}

.order-info li .value::before {
  content: ":";
  position: absolute;
  left: 0;
}

.order-detail .btn_edit {
  background-color: #01a54f;
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
}

.order-detail .cbd_info {
  background-color: #8f8f8f;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 3px 10px;
  display: inline-block;
  border-radius: 3px;
}

.order-detail hr.customer_details {
  margin-top: 0px;
}

.order-detail .documents-privew-img {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.order-detail .documents-privew-img li {
  width: auto;
  padding: 5px;
}

.order-detail .documents-privew-img img {
  max-width: 130px;
  cursor: pointer;
}

.order-detail .doc-img-privews {
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px #f0f0f0;
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table_header th {
  background-color: #e1f5fe !important;
  color: #01458e !important;
}

.text-primary {
  color: #01458e !important;
}

.service-sidebar {
  background-color: #fff;
  padding: 15px;
}

.order-filters {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 280px);
  justify-content: end;
  padding-left: 1rem;
}

.order-filters .search-filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.order-filters .search-filter label {
  margin-right: 10px;
}

.order-filters .search-filter .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.order-filters .search-filter .mat-mdc-text-field-wrapper {
  background: #fff !important;
  padding: 0;
  height: 35px;
}

.order-filters .search-filter .mat-mdc-form-field-infix {
  padding: 0 !important;
  min-height: inherit;
  height: 100%;
  width: auto !important;
}

.order-filters .search-filter .mat-mdc-form-field-flex {
  height: 100%;
}

.order-filters .search-filter .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  padding: 5px 10px;
  border: solid 1px #ddd;
  height: 100%;
  border-radius: 5px;
}

.order-filters .search-filter .mdc-line-ripple,
.order-filters .search-filter .mdc-line-ripple::before,
.order-filters .search-filter .mdc-line-ripple::after {
  display: none;
}

.order-filters .search-filter.date_bx .mat-mdc-form-field-infix {
  width: 100px !important;
}

.order-filters .search-filter.date_bx .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
  padding: 5px 4px;
  font-size: 14px;
}

.custom-mat-table .mat-mdc-table {
  border: 1px solid #ddd;
}

.custom-mat-table .mat-mdc-table thead {
  background-color: #01a54f;
}

.custom-mat-table .mat-mdc-table tr {
  height: auto !important;
}

.custom-mat-table .mat-mdc-table tr td,
.custom-mat-table .mat-mdc-table th {
  padding: 10px;
}

.custom-mat-table .mat-mdc-table thead tr th {
  color: #ffffff;
  font-size: 13px;
  vertical-align: middle;
  text-transform: uppercase;
}

.custom-mat-table .custom-mat-paginator .mat-mdc-paginator-outer-container {
  background: #fff;
}

.custom-mat-table .custom-mat-paginator .mat-mdc-paginator-container {
  min-height: inherit !important;
  overflow: hidden;
}

.custom-mat-table .custom-mat-paginator .mat-mdc-paginator-container .mat-mdc-paginator-range-label {
  margin: 0 5px 0 5px;
}

/*13-07-2024*/
.chapter_training .terms_of_use {
  padding-left: 1rem;
}

.onlinesvcform .form-control {
  padding: 0 1rem;
}

.onlinesvcform .custom-label {
  display: block;
}

.vcard {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e8e8e8;
}

.vehicle_detail {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.vehicle_detail .view {
  width: calc(100% - 40px);
  padding-left: 20px;
}

.fs-15 {
  font-size: 15px !important;
}

.rto_detail ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.rto_detail ul li .heading {
  font-size: 12px;
  font-weight: 500;
  color: #888;
}

.rto_detail ul li .value {
  font-size: 13px;
}

.rto_detail ul li.rtoyear {
  width: 40px;
  padding-left: 0;
}

.rto_detail ul li {
  width: calc(100% - 40px);
  padding-left: 20px;
}

.company_quotes {
  display: flex;
  width: calc(100% - 200px);
  flex-wrap: wrap;
  align-items: center;
}

.company-plans .plan-cover {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #fff !important;
}

.add-ons .form-check-label {
  font-size: 13px;
}

.pwa-icon {
  cursor: pointer;
}

.insurer_info .insurer_name {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
}

.insurer_info i {
  cursor: pointer;
}

/* ===== Blog Css Start ===== */
.blog .blog_content {
  padding: 0 20px
}
.blog .blog_content .title:hover{
  color: #01A54F!important;
}

.blog .blog_content .blog_title {
  font-size: 18px;
  margin: 15px 0px 15px 0px;
}

.blog .blog_image {
  margin-bottom: 15px;
  overflow: hidden;
}

.blog .input-group-text {
  background-color: #01A54F;
}

.blog .second_blog .form-control {
  border: solid 1px #dee2e6
}

.blog .blog_image a img {
  height: 200px;
  width: 100%;
}

.blog .second_blog .latest_post li {
  display: table;
  width: 100%;
  margin-bottom: 25px;
}

.blog .second_blog .latest_post li .post_pic {
  border-radius: 4px;
  width: 50px;
  float: left;
  overflow: hidden;
}

.blog .second_blog .latest_post .post_info {
  margin-left: 80px
}

.blog .second_blog .blog_categories li {
  margin-bottom: 20px
}

.blog .second_blog .tagging_tags .tag_group {
  background-color: #fff;
  border-radius: 4px;
  color: #26292c;
  padding: 5px 10px;
  position: relative;
  margin: 0 .5rem .5rem 0;
  font-size: 14px;
  border: 1px solid #ccc;
  display: inline-block;
}

.blog .second_blog .tagging_tags .tag_group:hover {
  background-color: #01A54F;
  border-color: #01A54F;
  color: #fff;
}

.post_info {
  max-width: 270px;
}

.heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* ===== Blog Css End ===== */

/* ===== Blog Detail Css Start ===== */
.blog .blog_details_control {
  height: 110px;
}

.blog .minor_spacings {
  padding: 0 20px
}

.blog .blog_image.pics_section_space {
  padding: 20px;
  overflow: hidden
}

.blog .blog-detail-box {
  border: solid 1px #01A54F;
  background-color: #01A54F;
}
/* ===== Blog Detail Css End ===== */

.vehicle_detail ul{display: flex; width: 100%; flex-wrap: wrap;}
.vehicle_detail ul li{display: block; width: 100%; padding: 8px 0; border-bottom: solid 1px #e9e9e9;}
.vehicle_detail ul li:last-child{border-bottom: none;}
.vehicle_detail ul li .heading{color:#01A54F; font-size:14px; font-weight: 500; margin-bottom: 3px;}
.vehicle_detail ul li .value{font-weight: 500; text-transform: uppercase; font-size: 14px;}
.possticky{position: sticky; top: 30px;}
.minhight230{min-height: 230px;}
.vhRow{display: flex; flex-wrap: wrap; width: 100%;}
.vhRow .vhSelect{width: calc(100% - 190px); padding-right: 0.5rem;}
.vhSelect .typeradio.form-check{padding-right: 0.5rem; width: 50%;}
.vhSelect .typeradio.form-check:last-child{padding-right: 0;}
.vehicle_organization_row{width: 190px;}
.btn-hover-green:hover{color:#01A54F!important;}
.vehicleInfo .grouplist {width: 100%; display: flex; flex-wrap: wrap;}
.vehicleInfo .grouplist li{width: 25%;}
.vehicleInfo .grouplist .typeradio.form-check{height: auto; width: 100%;}
.vehicleInfo .typeradio.form-check label{font-size: 14px;}
.vehicleInfo .typeradio.form-check .imgspace{width: 50px;}
.vehicleInfo .typeradio.form-check .makename{padding-left: 1rem;}
.vehicle-make .typeradio.form-check label{height: 60px;}